import { Injectable } from '@angular/core';
import pako from 'pako';

@Injectable({
  providedIn: 'root',
})
export class EncoderService {
  encodeJsonObject(jsonObj: any): string {
    // Convert JSON object to UTF-8 string
    const jsonString = JSON.stringify(jsonObj);

    // Convert string to Uint8Array (UTF-8 encoding)
    const utf8Encoder = new TextEncoder();
    const uint8Array = utf8Encoder.encode(jsonString);

    // Gzip compression using pako
    const compressed = pako.gzip(uint8Array);

    // Base64 encoding
    const base64String = btoa(String.fromCharCode(...compressed));

    // URL encoding
    return encodeURIComponent(base64String);
  }
}
