import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { getFileIcon, removeDirectories } from '@shared/util/file.util';
import { TranslateService } from '@ngx-translate/core';
import { AllowType, DocType, FileData, Realm } from '@data/file/file.model';
import { FileQuery } from '@data/file/file.query';
import { Observable, of } from 'rxjs';
import { SpaceIonosService } from '@core/services/http/upload/space-ionos.service';
import { MAX_FILE_SIZE } from '@data/file/file.store';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';

@Component({
  selector: 'recrewt-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadComponent implements OnChanges {
  @Input() multiple = false;

  @Input() addTimestamp = false;

  @Input() fileLimit?: number;

  @Input() accept: AllowType[] = ['*/*'];

  @Input() realm?: Realm;

  @Input() docType?: DocType;

  @Input() for?: string;

  @Input() progress: Map<string, number> = new Map();

  @Output() deleted = new EventEmitter<FileData>();

  @Output() uploaded = new EventEmitter<File>();

  selectedFiles$: Observable<FileData[]> = of([]);

  newFiles: File[] = [];

  error?: string;

  noPreview = false;

  readonly apiUrl = environment.apiUrl;

  constructor(
    private uploadService: SpaceIonosService,
    private fileQuery: FileQuery,
    private translate: TranslateService,
  ) {}

  @Input() filterFiles: (file: FileData) => boolean = () => true;

  trackByName(file: FileData): string {
    return file.name;
  }

  fileIcon(file: FileData): string {
    return getFileIcon(file.type);
  }

  isFiles = (items: unknown[]): items is FileData[] => {
    return !!(items as FileData[])[0].docType;
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes.for || changes.realm || changes.docType || changes.filterFiles) {
      this.loadFiles();
    }

    this.noPreview = !this.accept.includes('image/*') && !this.accept.includes('video/*');
  }

  handleDrop(fileList: FileList): void {
    removeDirectories(fileList).then((files: File[]) => {
      if (files?.length) {
        this.uploadFiles(files);
      }
    });
  }

  onFileSelected(event: Event): void {
    const fileList = (event.target as HTMLInputElement)?.files;
    if (!fileList) {
      return;
    }

    removeDirectories(fileList).then((files: File[]) => {
      if (files?.length) {
        this.uploadFiles(files.slice(0, this.multiple ? files.length : 1));
      }
    });
  }

  removeFiles(items: unknown[]): void {
    if (!this.isFiles(items)) {
      return;
    }

    const files = items as FileData[];
    files.forEach((file) => {
      this.newFiles = this.newFiles.filter((it) => it.name !== file.name);
      this.deleted.emit(file);
    });
  }

  download(items: unknown[], toDisk = true): void {
    if (!this.isFiles(items)) {
      return;
    }

    const files = items as FileData[];
    files.forEach((f) => {
      if (!this.hasValidIonosConfig()) {
        return;
      }

      this.uploadService.download(this.realm!, this.docType!, this.for!, f.name).subscribe((data) => {
        if (!toDisk) {
          return;
        }
        const downloadURL = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = f.name;
        link.click();
        window.URL.revokeObjectURL(downloadURL);
      });
    });
  }

  private loadFiles() {
    if (this.for && this.realm && this.docType) {
      this.selectedFiles$ = this.fileQuery
        .selectFor(this.realm, this.for, this.docType)
        .pipe(map((files) => files.filter(this.filterFiles)));
      this.uploadService.meta(this.realm, this.docType, this.for).subscribe();
    }
  }

  private uploadFiles(files: File[]): void {
    this.error = undefined;
    try {
      this.checkMaxFileLimitReached(files);
      this.checkMaxFileSizeReached(files);
      files.forEach((file) => {
        if (this.addTimestamp) {
          const blob = file.slice(0, file.size, 'image/png');
          file = new File([blob], `${Date.now()}_${file.name}`, { type: file.type });
        }
        this.checkValidFileType(file);
        this.checkDuplicateFileName(file);
        this.newFiles.push(file);
        this.uploaded.emit(file);
      });
    } catch (e) {
      return;
    }
  }

  private checkMaxFileLimitReached(files: File[]) {
    let fileCount = 0;
    if (this.hasValidIonosConfig()) {
      fileCount = this.fileQuery.getCountFor(this.realm!, this.for!, this.docType!, this.filterFiles);
    }

    if (!!this.fileLimit && fileCount + files.length > this.fileLimit) {
      this.error = this.translate.instant('FILE_UPLOAD.err_too_many', {
        limit: this.fileLimit,
      });
      throw new Error(this.error);
    }
  }

  private checkMaxFileSizeReached(files: File[]) {
    let cumulativeSize = [...this.newFiles, ...files].reduce((sum, f) => (sum += f.size), 0);
    if (this.hasValidIonosConfig()) {
      cumulativeSize += this.fileQuery.getCumulativeSize(this.realm!, this.for!, this.docType!);
    }

    if (cumulativeSize > MAX_FILE_SIZE) {
      this.error = this.translate.instant('FILE_UPLOAD.err_size', {
        size: MAX_FILE_SIZE / 1024 / 1024,
      });
      throw new Error(this.error);
    }
  }

  private checkValidFileType(file: File) {
    if (!this.accept.some((t) => file.type.match(t))) {
      this.error = 'FILE_UPLOAD.err_type';
      throw new Error(this.error);
    }
  }

  private checkDuplicateFileName(file: File) {
    let sameFileUploaded = null;
    if (this.hasValidIonosConfig()) {
      sameFileUploaded = !!this.fileQuery.getEntityByNameFor(this.realm!, this.for!, this.docType!, file.name);
    }

    if (sameFileUploaded || this.newFiles.some((it) => it.name === file.name)) {
      this.error = this.translate.instant('FILE_UPLOAD.err_name', {
        name: file.name,
      });
      throw new Error(this.error);
    }
  }

  private hasValidIonosConfig() {
    return this.docType && this.for && this.realm;
  }
}
