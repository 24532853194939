import { SpaceIonosService } from '@core/services/http/upload/space-ionos.service';
import { JobQuery } from '@data/job/job.query';
import { createTemplateFromDraft, Job, JobCreatorFinishedPayload, JobDraft } from '@data/job/job.model';
import { JobService } from '@data/job/job.service';
import { JobCreatorWizardService } from '../../../modules/job-creator/core/job-creator-wizard.service';
import { ContextMode } from '@core/enums/app/context-mode';
import { DialogService } from '@shared/components/dialog/dialog.service';
import { AddJobToFairsDialogComponent } from '../../../modules/job-creator/dialog/add-job-to-fairs-dialog/add-job-to-fairs-dialog.component';
import { SpaceQuery } from '@data/space/space.query';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { first, Observable, of } from 'rxjs';
import { AddJobToBranchesDialogComponent } from '../../../modules/job-creator/dialog/add-job-to-branches-dialog/add-job-to-branches-dialog.component';
import { Space } from '@data/space/space.model';
import { Company } from '@data/company/company.model';
import { cloneDeep } from 'lodash';

type Result = { data: JobCreatorFinishedPayload; job: Job; branches?: [Space, Company][] };
export abstract class ResourceHandler {
  constructor(
    protected ionos: SpaceIonosService,
    private spaceQuery: SpaceQuery,
    protected jobQuery: JobQuery,
    protected jobService: JobService,
    protected jobCreator: JobCreatorWizardService,
    protected dialog: DialogService<any, any>,
  ) {}

  abstract onClicked(job: Job, args?: any): void;

  delete(job: Job): void {
    if (!job) return;
    this.deleteBanner(job.id);
    this.deleteMedia(job.id);
  }

  openJobCreator(draft?: Partial<JobDraft>) {
    this.jobCreator.open(draft);
    this.waitForJobCreationCompletion();
  }

  createTemplate(job: Job, banner: File | null, media: File[] | null) {
    return this.jobService.create(createTemplateFromDraft(job)).pipe(
      tap((tpl) => {
        this.saveBanner(banner, tpl?.id);
        this.saveMedia(media, tpl?.id);
      }),
    );
  }

  protected createJob(data: JobCreatorFinishedPayload) {
    this.jobService
      .create(data.job, data.autoCreateConfig)
      .pipe(
        filter((job) => !!job?.id),
        tap((job) => this.saveMediaAndTemplateForJob(data, job!)),
        switchMap((job) => this.askAddJobToFairs(job!, data)),
        switchMap((job) => this.askAddJobToBranches({ job, data })),
        first(),
      )
      .subscribe((result) => this.createAdditionalJobs(result));
  }

  private saveMediaAndTemplateForJob(data: JobCreatorFinishedPayload, job: Job) {
    this.saveBanner(data.banner, job!.id);
    this.saveMedia(data.media, job!.id);
    if (data.saveTpl && !data.skipCreateAdditional) {
      this.createTemplate(job!, data.banner, data.media);
    }
  }

  private askAddJobToFairs(job: Job, data: JobCreatorFinishedPayload) {
    if (job!.mode !== ContextMode.FAIR) {
      return of(job);
    }
    return this.addJobToFairsDialog(job, data);
  }

  private addJobToFairsDialog(job: Job, data: JobCreatorFinishedPayload) {
    this.dialog.open(AddJobToFairsDialogComponent, { job: job, preselect: [data.fairId] }, { width: '80vw' });
    return this.dialog.confirmed().pipe(map(() => job));
  }

  private askAddJobToBranches(result: Result) {
    if (result.job.mode !== ContextMode.FAIR || result.data.skipCreateAdditional) {
      return of(result).pipe(first());
    }

    const hasOrIsBranch$ = this.spaceQuery.hasOrIsBranch$;
    return hasOrIsBranch$.pipe(
      first(),
      switchMap((hasOrIsBranch) => {
        if (!hasOrIsBranch) {
          return of(result).pipe(first());
        }
        return this.addJobToBranchesDialog(result.job).pipe(
          tap((branches) => (result.branches = branches)),
          map(() => result),
        );
      }),
    );
  }

  private addJobToBranchesDialog(job: Job) {
    this.dialog.open(AddJobToBranchesDialogComponent, { job }, { width: '80vw' });
    return this.dialog.confirmed() as Observable<[Space, Company][]>;
  }

  private createJobForBranch(branch: Space, company: Company, data: JobCreatorFinishedPayload) {
    const job: JobDraft = {
      ...data.job,
      siteId: company.sites![0].id,
      location: company.sites![0].location,
      company: branch.companyRef,
    };
    if (data.autoCreateConfig) {
      data.autoCreateConfig.siteId = company.sites![0].id;
    }
    return { ...data, job, skipCreateAdditional: true } as JobCreatorFinishedPayload;
  }

  private createAdditionalJobs(result: Result) {
    result.data.skipCreateAdditional = true;

    this.createJobsForNextYears(result.data);

    result.branches?.forEach(([branch, company]) => {
      this.createJob(this.createJobForBranch(branch, company, result.data));
      this.createJobsForNextYears(this.createJobForBranch(branch, company, result.data));
    });
  }

  private createJobsForNextYears(data: JobCreatorFinishedPayload) {
    if (!data.autoCreateConfig?.enabled) {
      return;
    }

    const currentYear = new Date().getFullYear()!;
    for (let i = 1; i <= 2; i++) {
      if (i % data.autoCreateConfig.interval > 0) {
        continue;
      }
      const startYear = data.job.startDate?.getFullYear()!;
      if (currentYear + i === startYear) {
        continue;
      }
      const yearsUntilCurrentYear = currentYear - startYear;
      const dataClone = cloneDeep(data);
      delete dataClone.autoCreateConfig;
      dataClone.job.startDate?.setFullYear(currentYear + i);
      dataClone.job.endDate?.setFullYear(dataClone.job.endDate!.getFullYear()! + yearsUntilCurrentYear + i);
      dataClone.job.deadline?.setFullYear(dataClone.job.deadline!.getFullYear()! + yearsUntilCurrentYear + i);
      dataClone.job.internshipSlots?.forEach((slot) => {
        slot.start?.setFullYear(slot.start.getFullYear() + yearsUntilCurrentYear + i);
        slot.end?.setFullYear(slot.end.getFullYear() + yearsUntilCurrentYear + i);
      });
      this.createJob(dataClone);
    }
  }

  private waitForJobCreationCompletion() {
    this.jobCreator.confirmed().subscribe((data) => {
      this.handleJobCreationCompletion(data);
    });
  }

  private handleJobCreationCompletion(data: JobCreatorFinishedPayload) {
    if (!data?.job) return;
    this.deleteBanner(data.job.id);
    this.deleteMedia(data.job.id);
    this.createJob(data);
  }

  private saveBanner(banner: File | null, jobId?: string) {
    if (!banner || !jobId) return;
    this.ionos.uploadDisplayImage('jobs', 'job/banner', jobId, banner);
  }

  private saveMedia(media: File[] | null, jobId?: string) {
    if (!media?.length || !jobId) return;
    for (const file of media) {
      this.ionos.upload('jobs', 'job/media', jobId, file);
    }
  }

  private deleteBanner(jobId?: string) {
    if (!jobId) return;
    this.ionos.removeDisplayImage('jobs', jobId, 'job/banner', 'banner');
  }

  private deleteMedia(jobId?: string) {
    if (!jobId) return;
    const media$ = this.ionos.meta('jobs', 'job/media', jobId);
    media$.subscribe((media) => {
      for (const file of media) {
        this.ionos.delete('jobs', 'job/media', jobId, file.name);
      }
    });
  }
}
