import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { createPopper, Instance } from '@popperjs/core';
import { FileData } from '@data/file/file.model';

@Component({
  selector: 'recrewt-media-preview-popup',
  templateUrl: './media-preview-popup.component.html',
  styleUrls: ['./media-preview-popup.component.scss'],
})
export class MediaPreviewPopupComponent implements OnInit, OnChanges, OnDestroy {
  @Input() mediaSource?: string | FileData;

  @Input() disabled = false;

  @Output() hoverStart = new EventEmitter();

  @ViewChild('popupContent') popupContent?: TemplateRef<any>;

  isLoading = false;

  isImage = false;

  isVideo = false;

  mediaUrl?: SafeUrl;

  hoverTimer: any;

  popperInstance: Instance | null = null;

  protected view?: EmbeddedViewRef<any>;

  constructor(
    private vcr: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    private el: ElementRef,
    private sanitizer: DomSanitizer,
    private zone: NgZone,
  ) {}

  ngOnInit() {
    this.el.nativeElement.addEventListener('mouseenter', this.onMouseEnter.bind(this));
    this.el.nativeElement.addEventListener('mouseleave', this.onMouseLeave.bind(this));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.mediaSource && !this.disabled) {
      this.processMediaSource();
    }
  }

  onMouseEnter() {
    if (this.disabled) return;
    this.hoverTimer = setTimeout(() => {
      this.hoverStart.emit();
      this.showPreview();
    }, 500);
  }

  onMouseLeave() {
    clearTimeout(this.hoverTimer);
    this.hidePreview();
  }

  showPreview() {
    if (!!this.popperInstance) return;

    this.isLoading = true;

    this.createPopper();

    this.processMediaSource();
  }

  hidePreview() {
    if (this.popperInstance) {
      this.popperInstance.destroy();
      this.popperInstance = null;
    }
  }

  createPopper() {
    this.view = this.vcr.createEmbeddedView(this.popupContent!);
    const popup = this.view?.rootNodes[0];

    document.body.appendChild(popup);
    popup.style.width = `250px`;

    this.zone.runOutsideAngular(() => {
      if (this.popupContent) {
        this.popperInstance = createPopper(this.el.nativeElement, popup, {
          placement: 'top',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 10],
              },
            },
          ],
        });
      }
      this.cdr.detectChanges();
    });
  }

  processMediaSource() {
    if (!this.mediaSource) {
      this.isLoading = false;
      return;
    }

    if (typeof this.mediaSource === 'string') {
      this.processUrl(this.mediaSource);
    } else {
      this.processBlob(this.mediaSource);
    }
  }

  processUrl(url: string) {
    this.detectMediaType(url)
      .then((type) => {
        this.setMediaType(type);
        this.mediaUrl = this.sanitizer.bypassSecurityTrustUrl(url);
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
      })
      .finally(() => {
        this.cdr.detectChanges();
      });
  }

  processBlob(blob: FileData) {
    if (!blob.blob) {
      this.isLoading = true;
      return;
    }
    const objectUrl = URL.createObjectURL(new File([blob.blob], blob.name));
    this.mediaUrl = this.sanitizer.bypassSecurityTrustUrl(objectUrl);

    const mimeType = blob.type;
    if (mimeType.startsWith('image/')) {
      this.isImage = true;
      this.isVideo = false;
    } else if (mimeType.startsWith('video/')) {
      this.isImage = false;
      this.isVideo = true;
    } else {
      const extension = blob.name.split('.').pop()?.toLowerCase() ?? '';
      if (['jpg', 'jpeg', 'png', 'gif', 'webp', 'svg'].includes(extension)) {
        this.isImage = true;
        this.isVideo = false;
      } else if (['mp4', 'webm', 'ogg', 'mov'].includes(extension)) {
        this.isImage = false;
        this.isVideo = true;
      }
    }

    this.isLoading = false;
  }

  async detectMediaType(url: string): Promise<'image' | 'video'> {
    const extension = url.split('.').pop()?.toLowerCase() ?? '';

    if (['jpg', 'jpeg', 'png', 'gif', 'webp', 'svg'].includes(extension)) {
      return 'image';
    } else if (['mp4', 'webm', 'ogg', 'mov'].includes(extension)) {
      return 'video';
    }

    try {
      const response = await fetch(url, { method: 'HEAD' });
      const contentType = response.headers.get('content-type');

      if (contentType?.startsWith('image/')) {
        return 'image';
      } else if (contentType?.startsWith('video/')) {
        return 'video';
      }
    } catch (error) {
      console.error('Fehler beim Erkennen des Medientyps:', error);
    }

    return 'image';
  }

  setMediaType(type: 'image' | 'video') {
    this.isImage = type === 'image';
    this.isVideo = type === 'video';
  }

  onMediaLoad() {
    this.isLoading = false;
    if (this.popperInstance) {
      this.popperInstance.update();
    }
  }

  ngOnDestroy() {
    if (this.mediaUrl && typeof this.mediaSource !== 'string') {
      URL.revokeObjectURL(this.mediaUrl as unknown as string);
    }

    if (this.popperInstance) {
      this.popperInstance.destroy();
    }

    this.el.nativeElement.removeEventListener('mouseenter', this.onMouseEnter);
    this.el.nativeElement.removeEventListener('mouseleave', this.onMouseLeave);
  }
}
