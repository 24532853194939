import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EntityState, EntityStore, QueryEntity } from '@datorama/akita';
import { tap } from 'rxjs/operators';
import { Identifiable } from '@data/identifiable';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataBaseService<In, T extends Identifiable> {
  protected url?: string;

  constructor(
    protected http: HttpClient,
    protected store: EntityStore<EntityState<T>, T>,
    protected query: QueryEntity<EntityState<T>, T>,
    protected route: string,
  ) {
    this.url = `${environment.apiUrl}/${this.route}`;
  }

  get(params?: any, useCache = true): void {
    const httpParams = new HttpParams({ fromObject: params });
    if (!useCache || !this.query.getHasCache()) {
      this.http.get<T[]>(`${this.url}`, { params: httpParams }).subscribe((it) => {
        this.store.set(it ?? []);
      });
    }
  }

  create(data: In): Observable<any> {
    return this.http.post<T>(`${this.url}`, data).pipe(
      tap((it) => {
        if (!it) {
          return;
        }
        this.store.add(it);
      }),
    );
  }

  update(id: string, data: Partial<T>, params: any) {
    const httpParams = new HttpParams({ fromObject: params });
    this.http.put(`${this.url}/${id}`, data, { params: httpParams }).subscribe(() => {
      this.store.update(id, data);
    });
  }

  delete(id: string, params?: any): void {
    const httpParams = new HttpParams({ fromObject: params });
    this.http.delete(`${this.url}/${id}`, { params: httpParams }).subscribe(() => {
      this.store.remove(id);
    });
  }
}
