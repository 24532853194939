import { ErrorHandler, Inject, Injectable, InjectionToken, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app.routing';
import { CoreModule } from '@core/core.module';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AuthGuard } from '@core/guards/auth/auth.guard';
import { VerifiedGuard } from '@core/guards/verified/verified.guard';
import { UnverifiedGuard } from '@core/guards/unverified/unverified.guard';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LayoutModule } from './layout/layout.module';
import * as de from '@angular/common/locales/de';

import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgChartsModule } from 'ng2-charts';
import { MatSortModule } from '@angular/material/sort';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { NgxStripeModule } from 'ngx-stripe';
import Rollbar from 'rollbar';
import { ChatEffects } from '@data/chat/chat.effects';
import { EffectsNgModule } from '@ngneat/effects-ng';
import { ApplicationEffects } from '@data/application/application.effects';
import { NotificationEffects } from '@data/notification/notification.effects';
import { QuillModule } from 'ngx-quill';
import { NgxImageCompressService } from 'ngx-image-compress';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { IconPickerService } from 'ngx-icon-picker';
import { registerLocaleData } from '@angular/common';

const rollbarConfig = {
  accessToken: environment.rollbar.key,
  captureUncaught: true,
  captureUnhandledRejections: true,
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

export function rollbarFactory(): Rollbar {
  return new Rollbar({ ...rollbarConfig, captureIp: 'anonymize', reportLevel: 'error', sendConfig: true });
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err: any): void {
    if (environment.production) {
      this.rollbar.error(err.originalError || err);
    } else {
      console.error(err);
    }
  }
}

const DATE_FORMAT = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    EffectsNgModule.forRoot([ChatEffects, ApplicationEffects, NotificationEffects], { dispatchByDefault: false }),
    AuthModule.forRoot({
      // useRefreshTokens: true,
      // cacheLocation: 'localstorage',
      ...environment.auth,
      httpInterceptor: {
        allowedList: [`${environment.apiUrl}/*`],
      },
    }),
    BrowserModule,
    BrowserAnimationsModule,
    NgChartsModule,
    MatSortModule,
    MatDatepickerModule,
    MatMomentDateModule,
    QuillModule.forRoot(),
    NgxStripeModule.forRoot(environment.stripe.publicKey),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),

    LayoutModule,
    CoreModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    AuthGuard,
    VerifiedGuard,
    UnverifiedGuard,

    NgxImageCompressService,
    IconPickerService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    registerLocaleData(de.default);
  }
}
