import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatChip } from '@angular/material/chips';
import { LimitedStack } from '@shared/util/limited-stack';
import { ChipEditorComponent } from '@shared/components/editor/chip-editor/chip-editor.component';
import { CategoricalSelectorConfig } from '../../model/categorical-selector.config';
import { ChipSelectionData } from '../../model/chip-selection-data';

@Component({
  selector: 'recrewt-categorical-selector-group',
  templateUrl: './categorical-chip-selector-group.component.html',
  styleUrls: ['./categorical-chip-selector-group.component.scss'],
})
export class CategoricalChipSelectorGroupComponent implements OnChanges {
  @Input() parentFormGroup?: UntypedFormGroup;

  @Input() config: CategoricalSelectorConfig[] = [];

  @Input() selected: ChipSelectionData[] = [];

  @Output() selectionChanged = new EventEmitter<ChipSelectionData[]>();

  @ViewChildren('cl') private chipLists?: QueryList<ChipEditorComponent>;

  private selectedChips: LimitedStack<ChipSelectionData> = new LimitedStack(3);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selected?.currentValue) {
      this.writeValue(changes.selected?.currentValue ?? []);
    }
  }

  updateChipSelection(fromAuto: boolean, value: string, chipIdx: number, listIdx: number): void {
    const newFav = { fromAuto, value, chipIdx, listIdx };
    const isFavorite = this.selectedChips.some((fav) => chipIdx === fav.chipIdx && listIdx === fav.listIdx);
    let poppedFav;
    if (isFavorite) {
      this.selectedChips.filter((chip) => chip.chipIdx !== chipIdx || chip.listIdx !== listIdx);
      this.selectionChanged.emit(this.selectedChips.toArray());
      poppedFav = newFav;
    } else {
      const selectedChip = this.getChip(listIdx, chipIdx);
      poppedFav = this.selectedChips.push(newFav);
      if (!!selectedChip) {
        selectedChip.select();
      }
      this.selectionChanged.emit(this.selectedChips.toArray());
    }
    if (!!poppedFav) {
      const deselectedChip = this.getChip(poppedFav.listIdx, poppedFav.chipIdx);
      if (!!deselectedChip) {
        deselectedChip.deselect();
      }
    }
  }

  reselectChips(idxList: number): void {
    setTimeout(() => {
      this.selectedChips.forEach((fav) => {
        if (fav.listIdx === idxList) {
          const idxChip = fav.chipIdx;
          const selectedChip = this.getChip(idxList, idxChip);
          selectedChip?.select();
        }
      });
    }, 0); // required, otherwise chip color is not updated
  }

  readjustAfterAdded(fromAuto: boolean, listIdx: number, chipIdx: number): void {
    if (!fromAuto) {
      return;
    }
    this.selectedChips.forEach((fav, index) => {
      if (fav.listIdx !== listIdx) {
        return;
      }
      if (chipIdx <= fav.chipIdx) {
        fav.chipIdx += 1;
        this.selectedChips.set(fav, index);
      }
    });
    this.selectionChanged.emit(this.selectedChips.toArray());
  }

  readjustAfterRemove(listIdx: number, chipIdx: number): void {
    const toRemove: number[] = [];
    this.selectedChips.forEach((fav, index) => {
      if (fav.listIdx !== listIdx) {
        return;
      }
      if (chipIdx === fav.chipIdx) {
        toRemove.push(index);
      } else if (chipIdx < fav.chipIdx) {
        fav.chipIdx -= 1;
        this.selectedChips.set(fav, index);
      }
    });
    toRemove.forEach((idx) => this.selectedChips.remove(idx));
    this.selectionChanged.emit(this.selectedChips.toArray());
  }

  writeValue(obj: ChipSelectionData[]): void {
    setTimeout(() => {
      this.selectedChips.forEach((it) => this.getChip(it.listIdx, it.chipIdx)?.deselect());
      this.selectedChips.clear();
      obj.forEach((fav) => {
        this.updateChipSelection(fav.fromAuto, fav.value, fav.chipIdx, fav.listIdx);
      });
    }, 0);
  }

  private getChip(idxList: number, idxChip: number): MatChip | undefined {
    return this.chipLists?.get(idxList)?.chipList?.chips?.get(idxChip);
  }
}
