import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { ChartData } from 'chart.js';
import { TranslateService } from '@ngx-translate/core';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'recrewt-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent implements OnInit, OnChanges {
  public pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: 'bottom',
      labels: {
        usePointStyle: true,
      },
    },
  };

  @Input() public labels: any[] = [];

  @Input() public data: any[] = [];

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  public pieChartType = 'pie';

  public pieChartLegend = true;

  public pieChartPlugins = [];

  public pieChartData: ChartData<'pie', number[], string | string[]> = {
    datasets: [],
  };

  colors = [
    {
      hoverColor: ['#ff8f00', '#e53a7d', '#80093C', '#c73c3c', '#c73c3c', '#753659'],
      backgroundColor: ['#ff8f0066', '#e53a7d66', '#80093C66', '#c73c3c66', '#c73c3c66', '#75365966'],
    },
  ];

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    this.pieChartData = {
      labels: this.labels.map((label) => this.translate.instant(label)),
      datasets: [{ data: this.data }],
    };
    if (!this.pieChartData?.datasets?.[0]) return;
    this.pieChartData.datasets[0].backgroundColor = this.colors[0].backgroundColor;
    this.pieChartData.datasets[0].borderColor = this.colors[0].hoverColor;
    this.pieChartData.datasets[0].borderWidth = 3;
    this.pieChartData.datasets[0].hoverBackgroundColor = this.colors[0].hoverColor;
    this.pieChartData.datasets[0].hoverBorderColor = this.colors[0].backgroundColor;
  }

  ngOnChanges() {
    if (!this.pieChartData?.datasets?.[0]) return;
    this.pieChartData.datasets[0].data = this.data;
    this.chart?.update();
  }
}
