import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

export interface IStorageService {
  get(key: string): any;

  set(key: string, value: any): boolean;

  removeManyFromArray(arrKey: string, keys: string[]): boolean;

  removeByIdFromArray(arrKey: string, id: string, idKey: string): boolean;

  getFromArray(arrKey: string, key: string): any;

  addToArray(arrKey: string, value: any): boolean;

  remove(key: string): boolean;
}

export abstract class LocalStorageService implements IStorageService {
  localStorage: Storage;

  protected constructor() {
    this.localStorage = window.localStorage;
  }

  get isLocalStorageSupported(): boolean {
    return !!this.localStorage;
  }

  get(key: string): any {
    if (this.isLocalStorageSupported) {
      return this.ukey(key).pipe(
        map((k: any) => {
          const item = this.localStorage.getItem(k);
          if (item) {
            return JSON.parse(item);
          }
        }),
      );
    }
    return null;
  }

  set(key: string, value: any): boolean {
    if (!key || value === undefined) {
      return false;
    }
    if (this.isLocalStorageSupported) {
      this.ukey(key).subscribe((k: string) => {
        if (k) {
          this.localStorage.setItem(k, JSON.stringify(value));
        }
      });
      return true;
    }
    return false;
  }

  removeManyFromArray(arrKey: string, keys: string[]): boolean {
    if (this.isLocalStorageSupported) {
      this.get(arrKey).subscribe((arr: any[] | null) => {
        for (const key of keys) {
          const index = arr?.indexOf(key, 0) ?? -1;
          if (index > -1) {
            arr?.splice(index, 1);
          }
        }
        this.set(arrKey, arr);
      });
      return true;
    }
    return false;
  }

  removeByIdFromArray(arrKey: string, id: string, idKey: string = 'id'): any {
    if (this.isLocalStorageSupported) {
      let ret: any | null = null;
      this.get(arrKey).subscribe((arr: any[] | null) => {
        const index = arr?.findIndex((value) => value[idKey] === id) ?? -1;
        if (index > -1) {
          ret = arr?.splice(index, 1)?.[0];
        }
        this.set(arrKey, arr);
      });
      return ret;
    }
    return null;
  }

  getFromArray(arrKey: string, key: string): Observable<any> {
    if (this.isLocalStorageSupported) {
      return this.get(arrKey).pipe(
        map((arr: any[]) => {
          const index = arr.indexOf(key, 0);
          if (index > -1) {
            return arr[index];
          }
        }),
      );
    }
    return of(null);
  }

  addToArray(arrKey: string, value: any): boolean {
    if (this.isLocalStorageSupported) {
      this.get(arrKey).subscribe((arr: any[] | null) => {
        if (!arr) {
          arr = [];
        }
        arr.push(value);
        this.set(arrKey, arr);
      });
      return true;
    }
    return false;
  }

  remove(key: string): boolean {
    if (this.isLocalStorageSupported) {
      this.ukey(key).subscribe((k: string | null) => {
        if (k) {
          this.localStorage.removeItem(k);
        }
      });
      return true;
    }
    return false;
  }

  abstract ukey(key: string): any;
}
