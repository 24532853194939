import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { QuestionConfig } from '@shared/components/question-panel/card-deck-form/card-deck-form.component';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'recrewt-question-deck',
  templateUrl: './question-deck.component.html',
  styleUrls: ['./question-deck.component.scss'],
})
export class QuestionDeckComponent implements OnInit, OnChanges {
  @Input() questionSet: QuestionConfig[] = [];

  @Input() parentFormGroup?: UntypedFormGroup;

  @Input() initialValues: number[] = [];

  @Input() formArrName = '';

  @Input() completeMessage = '';

  answers: { title: string; value: string }[] = [];

  finished = false;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    const formArr = this.parentFormGroup?.get(this.formArrName) as UntypedFormArray;
    this.initializeFormArray(formArr, this.initialValues);
    this.initializeAnswers();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.parentFormGroup.currentValue && !changes.parentFormGroup.firstChange) {
      const formArr = this.parentFormGroup?.get(this.formArrName) as UntypedFormArray;
      formArr.clear();
      this.initializeFormArray(formArr, this.initialValues);
      this.initializeAnswers();
    }
  }

  initializeAnswers(): void {
    for (let i = 0; i < this.questionSet.length; i++) {
      this.updateAnswer(i);
    }
  }

  updateAnswer(i: number): void {
    if (i === this.questionSet.length) {
      return;
    }
    const q = this.questionSet[i];
    const selection = this.parentFormGroup?.get(this.formArrName)?.value[i.toString()];
    const prevSelection = this.parentFormGroup?.get(this.formArrName)?.value[(i - 1).toString()];
    let value = 'ACCORDION_LIST.no_select';
    if (selection && selection?.value !== -1) {
      value = selection.value ? q.truthyOption : q.falsyOption;
    }
    if (this.answers[i]) {
      if (prevSelection?.value !== 0 && q.showWhen === 'prev_falsy') {
        this.answers.splice(i, 1);
        return;
      }
      this.answers[i].value = value;
    } else {
      if (prevSelection?.value !== 0 && q.showWhen === 'prev_falsy') {
        return;
      }
      this.answers[i] = {
        title: q.title,
        value,
      };
    }
  }

  private initializeFormArray(stepValues: UntypedFormArray, initValues: number[]): void {
    this.questionSet?.forEach((q, i) => {
      stepValues.push(
        this.fb.group({
          value: this.fb.control(initValues[i] == null ? -1 : initValues[i], [Validators.min(0), Validators.max(1)]),
        }),
        { emitEvent: false },
      );
    });
  }
}
