<div class="b-rad-1 recrewt-card" fxFlex="grow">
  <div class="recrewt-card-header p-v-2 p-h-3" fxLayout="row" fxLayoutGap="8px">
    <button (click)="chooseClicked.emit()" color="accent" mat-flat-button type="button">
      <mat-icon>add</mat-icon>
      {{ 'COMMON.choose' | translate }}
    </button>
  </div>
  <div
    (dragenter)="activate($event)"
    (dragleave)="deactivate($event)"
    (dragover)="activate($event)"
    (drop)="onDrop($event)"
    class="width-match-parent"
    fxLayout="column"
    fxLayoutAlign="start start"
  >
    <div *ngIf="!!error" class="p-1 width-match-parent">
      <div [class.is-drag-over]="isDragover" class="p-2 error b-rad-1" fxFlex="grow">
        <p class="no-pad text-align-center">{{ error | translate }}</p>
      </div>
    </div>
    <ng-template [ngIfElse]="itemsTpl" [ngIf]="!!items?.length">
      <mat-selection-list #selectionList [class.is-drag-over]="isDragover" class="width-match-parent">
        <mat-list-option
          *ngFor="let item of items; index as idx; trackBy: trackByFn"
          [value]="item"
          class="auto"
          color="primary"
        >
          <div class="width-match-parent" fxFlex="grow">
            <ng-template [ngIf]="!itemTpl">
              {{ item }}
            </ng-template>
            <ng-template *ngTemplateOutlet="itemTpl; context: { item: item, idx: idx }"></ng-template>
          </div>
        </mat-list-option>
      </mat-selection-list>
      <div *ngIf="selectionList.selectedOptions.hasValue()" class="m-2">
        <button
          (click)="remove(selectionList.selectedOptions)"
          *ngIf="allowDelete"
          color="warn"
          mat-flat-button
          type="button"
        >
          {{ 'COMMON.delete' | translate }}
        </button>
        <ng-template
          *ngTemplateOutlet="
            additionalActions;
            context: { $implicit: selectedOptions(selectionList.selectedOptions.selected) }
          "
        ></ng-template>
      </div>
    </ng-template>
    <ng-template #itemsTpl>
      <div class="p-1 width-match-parent">
        <div [class.is-drag-over]="isDragover" class="p-2 dashed-1 b-rad-1" fxFlex="grow">
          <p class="no-pad text-align-center">{{ emptyHint | translate }}</p>
        </div>
      </div>
    </ng-template>
  </div>
</div>
