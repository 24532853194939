import { Injectable } from '@angular/core';
import { Order, QueryEntity } from '@datorama/akita';
import { JobGeneralState, JobGeneralStore } from './job-general.store';
import {
  BookableType,
  GeneralJobType,
  getBookableType,
  getGeneralJobType,
  JobGeneral,
} from '@data/job-general/job-general.model';
import { compare } from '@shared/util/array.util';

@Injectable({ providedIn: 'root' })
export class JobGeneralQuery extends QueryEntity<JobGeneralState, JobGeneral> {
  constructor(protected store: JobGeneralStore) {
    super(store);
  }

  getStandaloneJobs(): JobGeneral[] {
    return this.getAll({
      sortBy: (a, b) => compare(a.name.de, b.name.de, true),
      filterBy: (e) => e.isStandalone,
    });
  }

  getAvailableJobs(bookedJobTypes: string[]): JobGeneral[] {
    return this.getAll({
      sortBy: (a, b) => compare(a.name.de, b.name.de, true),
      sortByOrder: Order.DESC,
      filterBy: (e) => {
        return bookedJobTypes.includes(e.id) || (bookedJobTypes.includes(e.parentJobId ?? '') && !e.isStandalone);
      },
    });
  }

  getStudies(): JobGeneral[] {
    return this.getAll({
      sortBy: (a, b) => compare(a.name.de, b.name.de, true),
      filterBy: (e) => getGeneralJobType(e) === GeneralJobType.STUDY_INFO,
    });
  }

  getJobName(job: JobGeneral | string) {
    let j: JobGeneral | undefined;
    if (typeof job === 'string') {
      j = this.getEntity(job);
    } else {
      j = job;
    }
    if (!j) {
      return '';
    }

    const type = getBookableType(j);
    switch (type) {
      case BookableType.CLASSIC_TRAINING:
        return j.name.de;
      case BookableType.DUAL_STUDY:
        return 'Duales Studium in ' + j.name.de;
      case BookableType.COMPOUND_STUDY:
        return `${j.name.de} (Duales Verbundstudium)`;
    }
  }
}
