import { Component, Inject, OnInit } from '@angular/core';
import { JobDraft } from '@data/job/job.model';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EncoderService } from '@core/services/encoder/encoder.service';

@Component({
  selector: 'recrewt-job-preview',
  templateUrl: './job-mobile-preview.component.html',
  styleUrls: ['./job-mobile-preview.component.scss'],
})
export class JobMobilePreviewComponent implements OnInit {
  url: string | null = null;

  readonly BASE_URL = 'https://preview.app.recrewt.de/job';

  constructor(private encoder: EncoderService, @Inject(MAT_DIALOG_DATA) private job: string | JobDraft) {}

  ngOnInit(): void {
    if (typeof this.job === 'string') {
      this.url = `${this.BASE_URL}/${this.job}`;
    } else {
      const json = this.encoder.encodeJsonObject(this.prepareJobData(this.job));
      this.url = `${this.BASE_URL}/preview?json=${json}`;
    }
  }

  private prepareJobData(jobData: JobDraft): JobDraft {
    const job: any = { ...jobData };

    job.deadline = job.deadline ? new Date(job.deadline).getTime() : null;
    job.endDate = job.endDate ? new Date(job.endDate).getTime() : null;
    job.creationDate = job.creationDate ? new Date(job.creationDate).getTime() : null;
    job.startDate = job.startDate ? new Date(job.startDate).getTime() : null;

    return job;
  }
}
