import { Injectable } from '@angular/core';
import { EntityState, EntityStore, EntityUIStore, StoreConfig } from '@datorama/akita';
import { Job, JobUI } from './job.model';

export interface JobState extends EntityState<Job, string> {}
export interface JobUIState extends EntityState<JobUI> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'job', resettable: true })
export class JobStore extends EntityStore<JobState, Job, string> {
  ui!: EntityUIStore<JobUIState, JobUI>;

  constructor() {
    super();
    this.createUIStore();
  }

  akitaPreAddEntity(newEntity: Job): Job {
    function assertDate(date: any): Date {
      if (typeof date === 'string') {
        return new Date(Date.parse(date));
      }
      return date;
    }

    newEntity.creationDate = assertDate(newEntity.creationDate);
    newEntity.editDate = assertDate(newEntity.editDate);
    newEntity.endDate = assertDate(newEntity.endDate);
    newEntity.startDate = assertDate(newEntity.startDate);
    newEntity.deadline = assertDate(newEntity.deadline);
    newEntity.publishDate = assertDate(newEntity.publishDate);
    newEntity.publishedDate = assertDate(newEntity.publishedDate);
    newEntity.internshipSlots?.forEach((slot) => {
      slot.start = assertDate(slot.start);
      slot.end = assertDate(slot.end);
    });
    return super.akitaPreAddEntity(newEntity);
  }
}
