import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { ViewModeDirective } from '@shared/directives/view-mode.directive';
import { EditModeDirective } from '@shared/directives/edit-mode.directive';
import { from, fromEvent, mergeMap, ReplaySubject, Subject } from 'rxjs';
import { filter, switchMapTo, take, takeUntil } from 'rxjs/operators';

@Component({
  template: '',
})
export abstract class AbstractEditableComponent implements OnInit, OnDestroy {
  @Output() update = new EventEmitter();

  @Output() cancel = new EventEmitter();

  @ContentChild(ViewModeDirective) viewModeTpl?: ViewModeDirective;

  @ContentChild(EditModeDirective) editModeTpl?: EditModeDirective;

  @Input() mode: 'view' | 'edit' = 'view';

  protected editMode = new Subject();

  protected editMode$ = this.editMode.asObservable();

  protected destroyed$ = new ReplaySubject(1);

  constructor(private host: ElementRef) {}

  get currentView(): TemplateRef<any> | undefined {
    return this.mode === 'view' ? this.viewModeTpl?.tpl : this.editModeTpl?.tpl;
  }

  protected get element(): any {
    return this.host.nativeElement;
  }

  ngOnInit(): void {
    this.viewModeHandler();
    this.editModeHandler();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  toViewMode(): void {
    this.update.emit();
    this.mode = 'view';
  }

  cancelEdit(): void {
    this.cancel.emit();
    this.mode = 'view';
  }

  protected editModeHandler(): void {
    const events = ['click', 'focusin'];
    const clickOutside$ = from(events)
      .pipe(mergeMap((event) => fromEvent(document, event)))
      .pipe(
        filter((e) => {
          return (
            this.element.contains(e.target) === false && !(e.target as HTMLElement).className.includes('mat-calendar')
          );
        }),
        take(1),
      );

    this.editMode$.pipe(switchMapTo(clickOutside$), takeUntil(this.destroyed$)).subscribe(() => {
      this.update.emit();
      this.mode = 'view';
    });
  }

  protected abstract viewModeHandler(): void;
}
