import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ApplicationFlags {
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  jobDirty = false;

  companyDirty = false;

  quillValueChangeFiredGeneral = false;

  quillValueChangeFiredScholastic = 0;

  get dirty(): boolean {
    return this.jobDirty || this.companyDirty;
  }

  resetQuillValueChangeFiredFlags() {
    this.quillValueChangeFiredGeneral = false;
    this.quillValueChangeFiredScholastic = 0;
  }
}
