import { Component, Inject, OnInit } from '@angular/core';
import { SubscriptionService } from '@data/payment/subscription/subscription.service';
import { SubscriptionQuery } from '@data/payment/subscription/subscription.query';
import { combineLatestWith, forkJoin, Observable, of } from 'rxjs';
import { ObservableUtil } from '@shared/util/observable.util';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { map, switchMap, tap } from 'rxjs/operators';
import { ActionEvent } from '@shared/components/filterable-table/filterable-table.component';
import { Space } from '@data/space/space.model';
import { SpaceQuery } from '@data/space/space.query';
import { CompanyService } from '@data/company/company.service';
import { Job } from '@data/job/job.model';
import { Subscription } from '@data/payment/subscription/subscription.model';

type SpaceWithSubscriptions = { space: Space; subs: Subscription[] };

@Component({
  selector: 'recrewt-add-job-to-branches-dialog',
  templateUrl: './add-job-to-branches-dialog.component.html',
  styleUrls: ['./add-job-to-branches-dialog.component.scss'],
})
export class AddJobToBranchesDialogComponent implements OnInit {
  branches$: Observable<Space[]> = of([]);

  loading$ = of(false);

  submitLoading = false;

  private selectedBranches: Space[] = [];

  constructor(
    private subscriptionService: SubscriptionService,
    private subscriptionQuery: SubscriptionQuery,
    private companyService: CompanyService,
    private spaceQuery: SpaceQuery,
    private dialogRef: MatDialogRef<AddJobToBranchesDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: { preselect?: string[]; job: Job },
  ) {}

  ngOnInit(): void {
    this.subscriptionService.get();

    this.loading$ = ObservableUtil.combineBoolean(
      this.spaceQuery.selectLoading(),
      this.subscriptionQuery.selectLoading(),
    );

    this.branches$ = this.selectBranchesSupportingJobType();

    this.branches$.pipe(
      tap((it) => {
        if (!it.length) {
          this.onNoClick();
        }
      }),
    );
  }

  onSelectChange(event: ActionEvent<Space[]>) {
    if (event.actionId !== 'selectChange') {
      return;
    }
    this.selectedBranches = event.target;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit(): void {
    this.submitLoading = true;
    if (this.selectedBranches.length === 0) {
      this.dialogRef.close([]);
      return;
    }
    const result$ = this.selectedBranches.map((branch) => {
      const company$ = this.companyService.getOnce(branch.companyRef!);
      return of(branch).pipe(combineLatestWith(company$));
    });
    forkJoin(result$).subscribe((result) => {
      this.dialogRef.close(result);
    });
  }

  private selectBranchesSupportingJobType() {
    const activeSpace = this.spaceQuery.getActive()!;
    const parentRef = activeSpace.parentRef ?? activeSpace.id;
    const spaces$ = this.spaceQuery.selectAll({ filterBy: (s) => s.parentRef === parentRef && s.id != activeSpace.id });
    const subscriptions$ = this.loadSubscriptionForSpaces(spaces$);
    return this.selectSpacesWithActiveSubscriptionForJob(subscriptions$);
  }

  private selectSpacesWithActiveSubscriptionForJob(subscriptions$: Observable<SpaceWithSubscriptions[]>) {
    const job = this.data.job;
    return subscriptions$.pipe(
      map((spacesWithSubs) => {
        return spacesWithSubs
          .filter(({ subs }) =>
            subs.some(this.subscriptionQuery.isActiveSubscriptionForJob(job, job.status === 'queued', true)),
          )
          .map(({ space }) => space);
      }),
    );
  }

  private loadSubscriptionForSpaces(spaces$: Observable<Space[]>) {
    return spaces$.pipe(
      switchMap((spaces) =>
        forkJoin(
          spaces.map((space) => this.subscriptionService.getById(space.id).pipe(map((subs) => ({ space, subs })))),
        ),
      ),
    );
  }
}
