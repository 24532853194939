import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanySite } from '@data/company/company.model';
import { fromListValidator } from '@shared/components/editor/location-selector/location-selector.component';
import { randObjectId } from '@shared/util/string-util';

@Component({
  selector: 'recrewt-site-dialog',
  templateUrl: './site-dialog.component.html',
  styleUrls: ['./site-dialog.component.scss'],
})
export class SiteDialogComponent implements OnInit {
  form!: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<SiteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public site: CompanySite,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit(): void {
    const { value, valid } = this.form;
    if (valid) {
      this.dialogRef.close(value);
    }
  }

  ngOnInit(): void {
    const uniqueId = `${Date.now()}${randObjectId()}`;
    this.form = this.formBuilder.group({
      id: [this.site?.id ?? uniqueId],
      name: [this.site?.name, [Validators.required]],
      location: [this.site?.location, [Validators.required, fromListValidator()]],
      hq: [this.site?.hq ?? false],
    });
  }
}
