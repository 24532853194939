import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'recrewt-name-letter-avatar',
  templateUrl: './name-letter-avatar.component.html',
  styleUrls: ['./name-letter-avatar.component.scss'],
})
export class NameLetterAvatarComponent implements OnChanges {
  @Input() name = '';

  @Input() showName = false;

  @Input() size: 'small' | 'big' = 'small';

  acronym = '';

  color = '#ffffff';

  backgroundColor = '#000000';

  private static stringToColor(str: string): string {
    let hash = 0;
    if (str.length === 0) {
      return hash.toString(16);
    }
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + (hash * 32 - hash);
      // eslint-disable-next-line no-bitwise
      hash = hash & hash;
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
      // eslint-disable-next-line no-bitwise
      const value = (hash >> (i * 8)) & 255;
      color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
  }

  private static invertColor(hex: string): string {
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      hex = '000000';
    }
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
  }

  private static interweave(str1: string, str2: string): string {
    let l1 = str1?.length;
    let l2 = str2?.length;
    if (2 * l1 < l2) {
      str1 += str1;
      l1 = 2 * l1;
    } else if (2 * l2 < l1) {
      str2 += str2;
      l2 = 2 * l2;
    }
    const len = l1 > l2 ? l1 : l2;
    let str = '';
    for (let i = 0; i < len; i++) {
      str += str1[i] + str2[i];
    }
    return str;
  }

  ngOnChanges(): void {
    if (!this.name) {
      this.name = '-';
      this.acronym = '-';
    } else {
      this.acronym =
        this.name
          .match(/\b(\w)/g)
          ?.join('')
          .slice(0, 2)
          .toUpperCase() ?? '';
    }

    const names = this.name?.slice().split(' ');
    this.backgroundColor = NameLetterAvatarComponent.stringToColor(
      NameLetterAvatarComponent.interweave(names[0], names[1]).substring(0, 7),
    );
    this.color = NameLetterAvatarComponent.invertColor(this.backgroundColor);
  }
}
