<recrewt-click-editable (cancel)="onCancel()" (update)="onUpdate()">
  <ng-template viewMode>
    <p *ngIf="value" [class.keep-breaks]="multiline" [class.mat-h2]="size === 'h2'" class="no-pad p-1">{{ value }}</p>
    <p *ngIf="!value" [class.mat-h2]="size === 'h2'" class="on-surface-medium no-pad p-1">
      {{ placeholder ? (placeholder | translate) : '-' }}
    </p>
  </ng-template>
  <ng-template editMode>
    <ng-template [ngIfElse]="multilineTpl" [ngIf]="!multiline">
      <input
        (input)="onInputChanged()"
        *ngIf="editControl"
        [class.mat-body-1]="size === 'p'"
        [class.mat-h2]="size === 'h2'"
        [formControl]="editControl!"
        [ngStyle]="{ 'width.ch': value?.length, 'min-width.ch': 25 }"
        [type]="type"
        class="no-pad p-1 mat-styled-input"
        editableKeyListener
        fxFlex="grow"
        takeFocus
      />
    </ng-template>
    <ng-template #multilineTpl>
      <textarea
        (input)="onInputChanged()"
        *ngIf="editControl"
        [formControl]="editControl!"
        cdkTextareaAutosize
        class="mat-styled-input mat-body-1"
        fxFlex="grow"
        takeFocus
        type="text"
      ></textarea>
    </ng-template>
  </ng-template>
</recrewt-click-editable>
