import { guid } from '@datorama/akita';
import * as mime from 'mime';

export interface FileData {
  id: string;
  name: string;
  type: string;
  size: number;
  forId: string;
  docType: DocType;
  blob?: Blob;
  fqn: string;
}

export interface FileDataDTO {
  name: string;
  size: number;
}

export type DocType =
  | 'company/logo'
  | 'company/banner'
  | 'company/media'
  | 'company/privacy'
  | 'company/document'
  | 'job/file'
  | 'job/banner'
  | 'job/task'
  | 'job/media'
  | 'application/document';
export type Realm = 'companies' | 'jobs' | 'applications';

export type AllowType =
  | '*/*'
  | 'application/graphql'
  | 'application/json'
  | 'application/ld+json'
  | 'application/msword'
  | 'application/pdf'
  | 'application/sql'
  | 'application/vnd.api+json'
  | 'application/vnd.ms-excel'
  | 'application/vnd.ms-powerpoint'
  | 'application/vnd.oasis.opendocument.text'
  | 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  | 'application/x-www-form-urlencoded'
  | 'application/xml'
  | 'application/zip'
  | 'application/zstd'
  | 'audio/*'
  | 'audio/mpeg'
  | 'audio/ogg'
  | 'image/*'
  | 'image/gif'
  | 'image/jpeg'
  | 'image/png'
  | 'multipart/form-data'
  | 'text/*'
  | 'text/css'
  | 'text/csv'
  | 'text/html'
  | 'text/php'
  | 'text/plain'
  | 'text/xml'
  | 'video/*';

export function createFromFile(file: File, forId: string, docType: DocType): FileData {
  return {
    name: file.name,
    size: +file.size,
    type: file.type,
    forId,
    docType,
    id: guid(),
    blob: file,
    fqn: `${docType}/${forId}/${file.name}`,
  } as FileData;
}

export function createFromBlob(blob: Blob, forId: string, name: string, docType: DocType): FileData {
  return {
    name,
    size: +blob.size,
    forId,
    docType,
    id: guid(),
    blob,
    fqn: `${docType}/${forId}/${name}`,
  } as FileData;
}

export function createFromDTO(file: FileDataDTO, forId: string, docType: DocType): FileData {
  const mimetype = mime.getType(file.name);
  return {
    name: file.name,
    size: file.size,
    type: mimetype,
    forId,
    docType,
    id: guid(),
    fqn: `${docType}/${forId}/${file.name}`,
  } as FileData;
}
