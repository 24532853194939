import { SpaceIonosService } from '@core/services/http/upload/space-ionos.service';
import { JobQuery } from '@data/job/job.query';
import { SpaceRouter } from '@core/services/space-router/space-router.service';
import { JobService } from '@data/job/job.service';
import { JobCreatorWizardService } from '../../../modules/job-creator/core/job-creator-wizard.service';
import { CommitState, Job } from '@data/job/job.model';
import { spaceRouteNames } from '../../../modules/space/space.routing.names';
import { jobAdminRouteNames } from '../../../modules/job/job-admin.routing.names';
import { DialogService } from '@shared/components/dialog/dialog.service';
import { SpaceQuery } from '@data/space/space.query';
import { ResourceHandler } from '@core/services/resource/resource.handler';
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
} from '@shared/components/dialog/confirm-dialog/confirm-dialog.component';

export class JobHandler extends ResourceHandler {
  constructor(
    ionos: SpaceIonosService,
    spaceQuery: SpaceQuery,
    jobQuery: JobQuery,
    jobService: JobService,
    jobCreator: JobCreatorWizardService,
    dialog: DialogService<any, any>,
    private router: SpaceRouter,
  ) {
    super(ionos, spaceQuery, jobQuery, jobService, jobCreator, dialog);
  }

  delete(job: Job): void {
    this.dialog.open(ConfirmDialogComponent, {
      title: 'MENUS.JOB_DELETE.title',
      confirmText: 'COMMON.delete',
      confirmColor: 'warn',
      message: 'MENUS.JOB_DELETE.message',
    } as ConfirmDialogData);
    this.dialog.confirmed().subscribe((c) => {
      if (!c) return;

      super.delete(job);
      if (!this.isCommitted(job.id)) {
        this.deleteJobLocally(job);
      } else {
        this.jobService.delete(job.id)?.subscribe();
      }
    });
  }

  onClicked(job: Job, args?: any): void {
    const status = this.jobQuery.getCommitStatus(job.id);
    this.handleClickAccordingToCommitState(status, job, args);
  }

  tryToPersistJob(job: Job) {
    if (!job) return;
    this.jobService.syncJob(job.id, job, true);
  }

  private handleClickAccordingToCommitState(status: CommitState | undefined, job: Job, args: any) {
    if (JobQuery.hasCommitFailed(status)) {
      this.tryToPersistJob(job);
    } else if (JobQuery.isCommitted(status)) {
      this.navigateToJobDetails(job, args);
    }
  }

  private navigateToJobDetails(job: Job, args: any) {
    const y = job.startDate?.getFullYear();
    const s = job.siteId;
    const t = job.specialization ?? job.typeid?.toString();
    const queryParams = { t, s, y, ...args };
    this.router.navigate([spaceRouteNames.JOB_ADMIN, jobAdminRouteNames.JOB], { queryParams }).then();
  }

  private isCommitted(jobId: string): boolean {
    const status = this.jobQuery.getCommitStatus(jobId);
    return JobQuery.isCommitted(status);
  }

  private deleteJobLocally(job: Job) {
    this.jobService.deleteUncommittedJob(job.id);
  }
}
