import { Component, Input, OnInit } from '@angular/core';
import { Job } from '@data/job/job.model';
import { FormGroupDirective, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Education } from '@core/constants/job/education';
import { TRAINEE_FILTER } from '@data/job/job.filter';
import { get } from 'lodash';
import { enumValues } from '@shared/util/enum.util';
import { MapperUtil } from '@shared/util/mapper.util';

@Component({
  selector: 'recrewt-requirements-job-data-page',
  templateUrl: './requirements-job-data-page.component.html',
  styleUrls: ['./requirements-job-data-page.component.scss'],
})
export class RequirementsJobDataPageComponent implements OnInit {
  @Input() template: Partial<Job> = {};

  frmRequirements!: UntypedFormGroup;

  readonly EDU_LEVEL = enumValues(Education);

  readonly FILTER = TRAINEE_FILTER;

  constructor(private ctrlContainer: FormGroupDirective, private formBuilder: UntypedFormBuilder) {}

  eduMapping = (id: number) => MapperUtil.decToStr(id, 'education');

  ngOnInit(): void {
    const tplMatchData = this.template?.matchData;
    const education = tplMatchData?.general?.educationLevel;
    const requirements = tplMatchData?.general?.requirements;
    const filter = this.formBuilder.group({});
    this.FILTER.forEach((f) =>
      filter.addControl(f.id, this.formBuilder.control(get(tplMatchData?.filter, f.id, false))),
    );

    this.frmRequirements = this.formBuilder.group({
      filter,
      education: [education ?? 0, [Validators.required]],
      requirements: [requirements ?? []],
    });

    const parentForm = this.ctrlContainer.form;
    parentForm.addControl('requirements', this.frmRequirements);
  }

  isEduOptionActive(option: any): boolean {
    return option === this.frmRequirements.value.education;
  }

  selectEduOption(i: number): void {
    this.frmRequirements.controls.education?.setValue(i);
  }
}
