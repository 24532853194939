import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { CompanyStore } from '@data/company/company.store';
import { Company } from '@data/company/company.model';
import { Observable } from 'rxjs';
import { ObservableUtil } from '@shared/util/observable.util';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CompanyQuery extends Query<Company> {
  sites$ = this.select('sites').pipe(map((it) => it ?? []));

  private readonly IDENTIFIABLE_NAME = 'identifiableName';

  private readonly BRANCH = 'branch';

  private readonly SITES = 'sites';

  private readonly COMPANY_SIZE = 'size';

  constructor(protected store: CompanyStore) {
    super(store);
  }

  getId(): string | undefined {
    return this.getValue().id;
  }

  getSiteIds(): string[] {
    return this.getValue().sites?.map((site) => site.id) ?? [];
  }

  getActiveIdWhenReady(): Observable<string | undefined> {
    return ObservableUtil.takeWhenTruthy(this.select()).pipe(map((c) => c.id));
  }

  getEmptyMandatoryFields(): string[] {
    const c = this.getValue();
    return this.findMissingFields(c);
  }

  selectEmptyMandatoryFields(): Observable<string[]> {
    return this.select().pipe(map((c) => this.findMissingFields(c)));
  }

  private findMissingFields(c: Company) {
    const missing: string[] = [];
    if (!c.identifiableName?.length) {
      missing.push(this.IDENTIFIABLE_NAME);
    }
    if (c.branch == null && c.branches == null) {
      missing.push(this.BRANCH);
    }
    if (c.company_size == null) {
      missing.push(this.COMPANY_SIZE);
    }
    if (!c.sites?.length) {
      missing.push(this.SITES);
    }
    return missing;
  }
}
