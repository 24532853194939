import { Injectable } from '@angular/core';
import { JobService } from '@data/job/job.service';
import { JobCreatorWizardService } from '../../../modules/job-creator/core/job-creator-wizard.service';
import { Job, JobDraft, JobStatus } from '@data/job/job.model';
import { SpaceIonosService } from '@core/services/http/upload/space-ionos.service';
import { SpaceRouter } from '@core/services/space-router/space-router.service';
import { JobStore } from '@data/job/job.store';
import { ResourceHandler } from '@core/services/resource/resource.handler';
import { JobHandler } from '@core/services/resource/job.handler';
import { TemplateHandler } from '@core/services/resource/template.handler';
import { DraftHandler } from '@core/services/resource/draft.handler';
import { DialogService } from '@shared/components/dialog/dialog.service';
import { SpaceQuery } from '@data/space/space.query';
import { JobQuery } from '@data/job/job.query';

@Injectable({
  providedIn: 'root',
})
export class ResourceInteractionHandler {
  constructor(
    private ionos: SpaceIonosService,
    private spaceQuery: SpaceQuery,
    protected jobQuery: JobQuery,
    protected jobService: JobService,
    protected jobStore: JobStore,
    protected jobCreator: JobCreatorWizardService,
    protected router: SpaceRouter,
    protected dialog: DialogService<any, any>,
  ) {}

  get(): void {
    this.jobService.get();
  }

  for(status: JobStatus): ResourceHandler | undefined {
    switch (status) {
      case 'published':
      case 'queued':
      case 'archived':
      case 'inactive':
        return this.createJobHandler();
      case 'creation_paused':
        return this.createDraftHandler();
      case 'template':
        return this.createTemplateHandler();
      default:
        return undefined;
    }
  }

  delete(job: Job): void {
    this.for(job.status)?.delete(job);
  }

  createJob(draft?: Partial<JobDraft>): void {
    this.for('inactive')?.openJobCreator(draft);
  }

  private createJobHandler() {
    return new JobHandler(
      this.ionos,
      this.spaceQuery,
      this.jobQuery,
      this.jobService,
      this.jobCreator,
      this.dialog,
      this.router,
    );
  }

  private createDraftHandler() {
    return new DraftHandler(this.ionos, this.spaceQuery, this.jobQuery, this.jobService, this.jobCreator, this.dialog);
  }

  private createTemplateHandler() {
    return new TemplateHandler(
      this.ionos,
      this.spaceQuery,
      this.jobQuery,
      this.jobService,
      this.jobCreator,
      this.dialog,
      this.jobStore,
    );
  }
}
