import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Sort } from '@angular/material/sort';
import { EmptyConfig } from '@shared/components/placeholder/empty-page/empty-page.component';

export type ActionEvent<T> = { actionId: any; target: T };

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class FilterableTableComponent implements OnInit, OnChanges {
  emptyConfig?: EmptyConfig;

  hasItems$: Observable<boolean> = of(false);

  @Input() emptyTitle = '';

  @Input() emptyAction: string | undefined;

  @Input() emptyMessage = '';

  @Input() data$: Observable<any[]> = of([]);

  @Input() selected$: Observable<any[]> = of([]);

  @Input() loading$: Observable<boolean> = of(false);

  @Input() showActions = true;

  @Input() isSelectTable = false;

  @Output() action = new EventEmitter<ActionEvent<unknown>>();

  abstract sortBy: (sort: Sort, data: any[]) => any[];

  constructor(protected cdr: ChangeDetectorRef) {}

  @Input() filter: (item: any) => boolean = () => true;

  ngOnInit(): void {
    this.updateHasItems();
  }

  ngOnChanges(): void {
    this.updateHasItems();
    this.cdr.detectChanges();
  }

  onActionClick(actionId: any, target?: any): void {
    this.action.emit({ actionId, target });
  }

  private updateHasItems() {
    this.hasItems$ = this.data$.pipe(map((it) => it.some(this.filter)));
  }
}
