import { ColoredOption } from '@shared/components/editor/colored-select/data/colored-option';
import { JobStatus } from '@data/job/job.model';
import { COLOR_CONFIGS } from '@core/constants/app/status-colors';
import { StatusTagConfig } from '@shared/components/status-tag/status-tag.component';

export const JOB_STATUS_OPTIONS: ColoredOption[] = [
  {
    textValue: 'JOB_STATUS.inactive',
    value: 'inactive',
    color: '#e53a7d',
    backgroundColor: '#ffcfe3',
  },
  {
    textValue: 'JOB_STATUS.queued',
    value: 'queued',
    color: '#0055BC',
    backgroundColor: '#CFF5F6',
  },
  {
    textValue: 'JOB_STATUS.public',
    value: 'published',
    color: '#ff8f00',
    backgroundColor: '#fddeb3',
  },
  {
    textValue: 'JOB_STATUS.archived',
    value: 'archived',
    color: '#000000',
    backgroundColor: '#e0e0e0',
  },
];

export const JOB_STATUS_COLORS: { [key in JobStatus]: string } = {
  published: '#ff8f00',
  inactive: '#e53a7d',
  archived: '#000000',
  template: '#ffffff',
  creation_paused: '#1F6808',
  queued: '#0055BC',
  deleted: '#fd0000',
};

export const JOB_STATUS_TAGS: { [key in JobStatus]?: StatusTagConfig } = {
  published: {
    ...COLOR_CONFIGS.WARN,
    icon: 'done',
    text: 'JOB_STATUS.published',
  },
  inactive: {
    ...COLOR_CONFIGS.OK_VARIANT,
    icon: 'pause',
    text: 'JOB_STATUS.inactive',
  },
  queued: {
    ...COLOR_CONFIGS.INFO,
    icon: 'watch_later',
    text: 'JOB_STATUS.queued',
  },
  creation_paused: {
    ...COLOR_CONFIGS.OK,
    icon: 'edit',
    text: 'JOB_STATUS.creation_paused',
  },
  archived: {
    ...COLOR_CONFIGS.GRAY,
    icon: 'archive',
    text: 'JOB_STATUS.archived',
  },
  template: {
    ...COLOR_CONFIGS.GRAY,
    icon: 'content_copy',
    text: 'JOB_STATUS.template',
  },
};

export function getJobStatusTag(jobStatus?: JobStatus): StatusTagConfig | undefined {
  return jobStatus ? JOB_STATUS_TAGS[jobStatus] : undefined;
}
