import { Injectable } from '@angular/core';
import { EntityUIQuery, QueryEntity } from '@datorama/akita';
import { SpaceState, SpaceStore, SpaceUIState } from './space.store';
import { Space, SpaceUI } from '@data/space/space.model';
import { defaultIfEmpty, filter, map, switchMap, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ContextMode } from '@core/enums/app/context-mode';
import { randObjectId } from '@shared/util/string-util';

@Injectable({ providedIn: 'root' })
export class SpaceQuery extends QueryEntity<SpaceState, Space> {
  ui!: EntityUIQuery<SpaceUIState, SpaceUI>;

  mode$ = this.selectActiveMetadata().pipe(
    map((meta) => meta.mode ?? ContextMode.DEFAULT),
    defaultIfEmpty(ContextMode.DEFAULT),
  );

  isBranch$ = this.selectActive().pipe(map((space) => !!space?.parentRef));

  hasBranches$ = this.selectActive().pipe(map((space) => !!space?.branched));

  hasOrIsBranch$ = this.selectActive().pipe(map((space) => !!space?.branched || !!space?.parentRef));

  isActive$ = this.selectActiveId().pipe(map((id) => !!id));

  parentAndBranches$ = this.selectActive().pipe(
    switchMap((space) => {
      if (space?.branched) {
        return this.selectAll({ filterBy: (entity) => entity.parentRef === space?.id || entity.id === space?.id });
      } else if (space?.parentRef) {
        return this.selectAll({
          filterBy: (entity) => entity.parentRef === space?.parentRef || entity.id === space?.parentRef,
        });
      } else {
        return this.selectAll({ filterBy: (entity) => entity.id === space?.id });
      }
    }),
  );

  constructor(protected store: SpaceStore) {
    super(store);
    this.createUIQuery();
  }

  getActiveMode(): ContextMode {
    return this.ui.getEntity(this.getActiveId())?.mode ?? ContextMode.DEFAULT;
  }

  getActiveWhenReady(): Observable<Space | undefined> {
    return this.selectActive().pipe(
      filter((x) => !!x),
      take(1),
    );
  }

  getActiveIdWhenReady(): Observable<string | undefined> {
    return this.selectActiveId().pipe(
      filter((x) => !!x),
      take(1),
      map((value) => value?.toString()),
    );
  }

  selectActiveMetadata(): Observable<SpaceUI> {
    return this.selectActive().pipe(
      switchMap((space) => {
        const defaultValue = {
          id: space?.id ?? randObjectId(),
          applyTheme: false,
          mode: ContextMode.DEFAULT,
        };
        return this.ui.selectEntity(space?.id).pipe(
          map((ui) => ui ?? defaultValue),
          defaultIfEmpty(defaultValue),
        );
      }),
    );
  }
}
