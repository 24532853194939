import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'recrewt-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnInit, OnChanges {
  @Input() public labels: any[] = [];

  @Input() public data: any[] = [{ data: [], label: '' }];

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  public lineChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0.5,
      },
    },
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          maxTicksLimit: 12,
        },
      },
      y: {
        ticks: {
          maxTicksLimit: 5,
          callback(value) {
            if (+value % 1 === 0) {
              return value;
            }
          },
        },
      },
    },
  };

  public lineChartColors: any[] = [
    {
      borderColor: '#ff8f00',
      backgroundColor: '#ff8f0066',
    },
    {
      borderColor: '#e53a7d',
      backgroundColor: '#e53a7d66',
    },
    {
      borderColor: '#80093C',
      backgroundColor: '#80093C66',
    },
    {
      borderColor: '#c73c3c',
      backgroundColor: '#c73c3c66',
    },
    {
      borderColor: '#753659',
      backgroundColor: '#75365966',
    },
  ];

  public lineChartLegend = true;

  public lineChartType: ChartType = 'line';

  public lineChartPlugins = [];

  public setColor(): void {
    for (let i = 0; i < Math.min(this.lineChartColors.length, this.data.length); i++) {
      if (!this.data[i]?.data?.length) continue;
      this.data[i].borderColor = this.lineChartColors[i].borderColor;
      this.data[i].backgroundColor = this.lineChartColors[i].backgroundColor;
      this.data[i].pointBackgroundColor = this.lineChartColors[i].backgroundColor;
      this.data[i].pointHoverBorderColor = this.lineChartColors[i].backgroundColor;
      this.data[i].fill = 'origin';
    }

    this.chart?.update();
  }

  ngOnInit() {
    this.setColor();
  }

  ngOnChanges() {
    this.setColor();
  }
}
