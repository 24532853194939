<mat-card class="m-t-1 mat-elevation-z2 b-rad-1">
  <header class="p-2">
    <mat-progress-bar [value]="(selected / questionSet.length) * 100" color="primary"></mat-progress-bar>
  </header>
  <form [formGroup]="parentFormGroup">
    <mat-horizontal-stepper
      #stepper
      (selectionChange)="onPageChange($event.previouslySelectedIndex, $event.selectedIndex)"
      [formArrayName]="formArrayName"
      [selectedIndex]="selected"
      class="no-stepper-header"
    >
      <mat-step
        *ngFor="let q of questionSet; index as i; first as first"
        [formGroupName]="i"
        [stepControl]="stepValues?.get([i])"
      >
        <div fxFlex="100" fxLayout="column">
          <div class="m-v-2">
            <p class="text-align-center mat-h3">{{ q.title | translate }}</p>
          </div>
          <div fxLayout="row" fxLayoutAlign="center center">
            <recrewt-animated-binary-selection
              (selectionChanged)="stepper.next(); parentFormGroup?.markAsUntouched()"
              [falsyText]="q.falsyOption"
              [truthyText]="q.truthyOption"
              [valueAs]="boolToInt"
              formControlName="value"
              fxFlex="grow"
            ></recrewt-animated-binary-selection>
          </div>
        </div>
      </mat-step>
      <mat-step>
        <div class="p-t-3" fxLayout="column" fxLayoutAlign="center center">
          <div class="m-b-3">
            <button (click)="stepper.reset()" class="big" color="primary" mat-flat-button>
              {{ 'CARD_DECK.answer_new' | translate }}
            </button>
          </div>
          <h3 class="text-align-center">{{ completeMessage | translate }}</h3>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
    <mat-error *ngIf="parentFormGroup?.invalid && parentFormGroup?.touched">{{ errorMessage | translate }}</mat-error>
  </form>
</mat-card>
