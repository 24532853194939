<div class="media-preview-container">
  <ng-content></ng-content>
</div>

<ng-template #popupContent>
  <div class="popup-inner">
    <div *ngIf="isLoading" class="loading-container">
      <mat-spinner diameter="40"></mat-spinner>
    </div>

    <div *ngIf="!isLoading" class="media-container">
      <img (load)="onMediaLoad()" *ngIf="isImage" [src]="mediaUrl" class="media-content" />

      <video (loadeddata)="onMediaLoad()" *ngIf="isVideo" [src]="mediaUrl" autoplay class="media-content" controls>
        Dein Browser unterstützt das Video-Element nicht.
      </video>
    </div>
  </div>
</ng-template>
