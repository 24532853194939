import { Component, EventEmitter, Output } from '@angular/core';
import { MenuComponent, MenuStrategy } from '@shared/menus/menu/menu.component';
import { Job } from '@data/job/job.model';

@Component({
  selector: 'recrewt-resource-menu',
  templateUrl: './resource-menu.component.html',
  styleUrls: ['./resource-menu.component.scss'],
})
export class ResourceMenuComponent extends MenuComponent<Job> {
  @Output() deleteClicked = new EventEmitter<any>();

  @Output() retryClicked = new EventEmitter<any>();

  private readonly defaultStrategy: ResourceMenuStrategy = {
    showDelete: () => true,
    showRetry: () => false,
  };

  strategy = this.defaultStrategy;

  delete(job: Job): void {
    this.deleteClicked.emit(job);
  }

  retry(job: Job): void {
    this.retryClicked.emit(job);
  }
}

export interface ResourceMenuStrategy extends MenuStrategy {
  showDelete(job: Job): boolean;

  showRetry(job: Job): boolean;
}
