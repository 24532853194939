import { Inject, Injectable } from '@angular/core';
import { SpaceStore } from '@data/space/space.store';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  normalize,
  normalizeAll,
  NormalizedSpaceData,
  normalizeOne,
  Space,
  SpaceContext,
  SpaceDTO,
  SpaceInput,
} from '@data/space/space.model';
import { NotificationService } from '@core/services/notification/notification.service';
import { UserData } from '@data/user-data/user-data.model';
import { UserDataStore } from '@data/user-data/user-data.store';
import { Observable, of } from 'rxjs';
import { SpaceQuery } from '@data/space/space.query';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SPACE_UI_STORAGE_KEY } from '@core/constants/system/storage-key';
import { CompanyService } from '@data/company/company.service';
import { resetStores } from '@datorama/akita';
import { CompanyQuery } from '@data/company/company.query';
import { ThemeHandlerService } from '@core/services/theme-handler/theme-handler.service';
import { UserStore } from '@data/user/user.store';
import { SERVICE_LOCAL_STORAGE } from '@core/constants/system/service-providers';
import { IStorageService } from '@core/services/storage/storage.service';
import { UserQuery } from '@data/user/user.query';
import { GA_EVENT_ID, GoogleAnalyticsService } from '@core/services/ga/google-analytics.service';
import { CollaboratorStore } from '@data/collaborator/collaborator.store';
import {
  Collaborator,
  CollaboratorData,
  CollaboratorMetadata,
  createCollaborator,
} from '@data/collaborator/collaborator.model';
import { StopSubService } from '@core/services/stop-sub/stop-sub.service';
import { ContextMode } from '@core/enums/app/context-mode';

@Injectable({
  providedIn: 'root',
})
export class SpaceService {
  isSpaceCreationInProgress = false;

  private url = `${environment.apiUrl}/space`;

  constructor(
    private userStore: UserStore,
    private userQuery: UserQuery,
    private spaceStore: SpaceStore,
    private spaceQuery: SpaceQuery,
    private stopService: StopSubService,
    private userDataStore: UserDataStore,
    private collaboratorStore: CollaboratorStore,
    private companyService: CompanyService,
    private companyQuery: CompanyQuery,
    private http: HttpClient,
    private notify: NotificationService,
    private themeSync: ThemeHandlerService,
    private ga: GoogleAnalyticsService,
    @Inject(SERVICE_LOCAL_STORAGE) private spaceStorage: IStorageService,
  ) {}

  get(): void {
    this.http.get<{ spaces: SpaceInput[]; owners: UserData[] }>(`${this.url}`).subscribe(
      (value) => {
        const norm = normalizeAll(value.spaces);
        this.spaceStore.set(norm.spaces);
        this.collaboratorStore.set(norm.collaborators);
        this.userDataStore.set(value.owners);
      },
      () => {
        this.notify.error('SNACKBAR.SPACE.ERROR.load');
      },
    );
  }

  create(space: SpaceDTO) {
    const uid = this.userQuery.userData()?.id;
    this.isSpaceCreationInProgress = true;
    return this.http.post<{ space: SpaceInput; owner: UserData }>(`${this.url}`, { space, uid }).pipe(
      catchError(() => {
        this.notify.error('SNACKBAR.SPACE.ERROR.creation');
        this.isSpaceCreationInProgress = false;
        return of(null);
      }),
      map((value) => {
        if (!value) return null;
        const norm = normalizeOne(value.space);
        this.spaceStore.add(norm.space);
        this.collaboratorStore.add(norm.collaborators);
        this.userDataStore.add(value.owner);
        this.notify.success('SNACKBAR.SPACE.SUCCESS.creation');
        this.ga.eventEmitter(GA_EVENT_ID.space_created, 'space', GA_EVENT_ID.space_created);
        this.isSpaceCreationInProgress = false;
        return norm.space;
      }),
    );
  }

  update(spaceId: string, space: SpaceDTO): void {
    this.http.put<{ collaborators: CollaboratorData[] }>(`${this.url}/${spaceId}`, { space }).subscribe({
      next: (data) => {
        this.userDataStore.setHasCache(false);
        // this.userDataStore.setLoading(true);

        const newCollaborators = data.collaborators.map((it) => createCollaborator(it, spaceId));
        this.collaboratorStore.addForSpace(spaceId, newCollaborators);

        this.spaceStore.update(spaceId, {
          ...space,
          collaborators: newCollaborators.map((it) => it.id.toString()),
        });
        this.notify.success('SNACKBAR.SPACE.SUCCESS.update');
        this.ga.eventEmitter(GA_EVENT_ID.space_updated, 'space', GA_EVENT_ID.space_updated);
      },
      error: () => this.notify.error('SNACKBAR.SPACE.ERROR.update'),
    });
  }

  delete(spaceId: string): void {
    this.http.delete(`${this.url}/${spaceId}`).subscribe({
      next: () => {
        this.spaceStore.remove(spaceId);
        this.notify.success('SNACKBAR.SPACE.SUCCESS.delete');
        this.ga.eventEmitter(GA_EVENT_ID.space_deleted, 'space', GA_EVENT_ID.space_deleted);
        window.location.reload();
      },
      error: () => this.notify.error('SNACKBAR.SPACE.ERROR.delete'),
    });
  }

  accept(spaceId: string, token: string): Observable<any> {
    const userData$ = this.userQuery.userData$;
    return userData$.pipe(
      switchMap((ud) => {
        return this.http.post(`${this.url}/accept`, { spaceId, token, uid: ud?.id, email: ud?.email }).pipe(
          catchError((err) => {
            this.notify.error('SNACKBAR.SPACE.ERROR.invite');
            throw err;
          }),
          tap(() => this.notify.success('SNACKBAR.SPACE.SUCCESS.invite')),
        );
      }),
    );
  }

  validate(spaceId: string): Observable<NormalizedSpaceData | null> {
    return this.userQuery.userData$.pipe(
      switchMap((ud) => {
        if (!ud) {
          return of(null);
        }
        return this.http
          .get<SpaceContext>(`${this.url}/validate/${ud.id}/${spaceId}`)
          .pipe(map((space) => normalize(space)));
      }),
    );
  }

  activate(space: Space): void {
    this.userDataStore.reset();
    this.spaceStore.setActive(space.id);
    this.userStore.update({ activeSpaceId: space.id.toString() });
    this.loadCompanyForSpace(space);
    this.loadSpaceMetadata();
  }

  deactivate(): void {
    const exclude = ['user', 'space', 'collaborators'];
    resetStores({ exclude });
    this.spaceStore.setActive(null);
    this.userStore.update({ activeSpaceId: undefined });
    this.stopService.stop();
    this.themeSync.stopSync();
  }

  toggleApplyTheme(applyTheme: boolean): void {
    this.spaceQuery.getActiveIdWhenReady().subscribe((id) => {
      this.spaceStore.ui.upsert(id, { applyTheme });
      this.spaceStorage.set(SPACE_UI_STORAGE_KEY, this.spaceQuery.ui.getAll());
    });
  }

  toggleMode(mode: ContextMode): void {
    this.spaceQuery.getActiveIdWhenReady().subscribe((id) => {
      this.spaceStore.ui.upsert(id, { mode });
      this.spaceStorage.set(SPACE_UI_STORAGE_KEY, this.spaceQuery.ui.getAll());
    });
  }

  updateCollaborator(spaceId: string, uid: string, change: Partial<CollaboratorMetadata>): void {
    this.collaboratorStore.update((it: Collaborator) => it.space === spaceId && it.userRef === uid, { ...change });
    this.http
      .put<any>(`${this.url}/${spaceId}/${uid}`, { data: change, uid })
      .subscribe({ error: () => this.notify.error('SNACKBAR.SPACE.ERROR.update') });
  }

  invite(email: string, sid: string, spaceName: string): void {
    this.http.put<any>(`${this.url}/invite/${sid}`, { email, spaceName }).subscribe({
      next: () => this.notify.success('SNACKBAR.SPACE.SUCCESS.reinvite'),
      error: () => this.notify.error('SNACKBAR.SPACE.ERROR.reinvite'),
    });
  }

  private loadCompanyForSpace(space: Space): void {
    this.companyService.get(space.companyRef);
    this.companyQuery
      .select()
      .pipe(
        filter((c) => !!c.id?.length),
        takeUntil(this.themeSync.syncStopped),
      )
      .subscribe((c) => this.themeSync.syncCompanyTheme(c));
  }

  private loadSpaceMetadata(): void {
    this.spaceStorage.get(SPACE_UI_STORAGE_KEY).subscribe((uiData: any[]) => {
      uiData = uiData?.filter((data) => Object.keys(data).length > 1);
      if (uiData) {
        this.spaceStorage.set(SPACE_UI_STORAGE_KEY, uiData);
        this.spaceStore.ui.upsertMany(uiData);
      }
    });
  }
}
