import { Job } from '@data/job/job.model';
import { ResourceHandler } from '@core/services/resource/resource.handler';

export class DraftHandler extends ResourceHandler {
  onClicked(job: Job): void {
    this.jobService.deleteLocalDraft(job.id);
    this.openJobCreator(job as Job);
  }

  delete(job: Job) {
    super.delete(job);
    this.jobService.deleteLocalDraft(job.id);
  }
}
