import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatListOption } from '@angular/material/list';

@Component({
  selector: 'recrewt-editable-list-group',
  templateUrl: './editable-list-group.component.html',
  styleUrls: ['./editable-list-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableListGroupComponent {
  @Input() allowDnD = false;

  @Input() allowDelete = true;

  @Input() allowAdd = true;

  @Input() error?: string;

  @Input() items: unknown[] = [];

  @Input() itemTpl?: TemplateRef<any>;

  @Input() additionalActions?: TemplateRef<any>;

  @Input() emptyHint?: string;

  @Output() dropped = new EventEmitter<FileList>();

  @Output() chooseClicked = new EventEmitter<void>();

  @Output() removed = new EventEmitter<unknown[]>();

  constructor(private cdr: ChangeDetectorRef) {}

  private _isDragover = false;

  get isDragover(): boolean {
    return this._isDragover && this.allowDnD;
  }

  set isDragover(isDragover) {
    this._isDragover = this.allowDnD ? isDragover : false;
  }

  @Input() trackByFn = (index: number, item: any): unknown => item;

  activate(e: DragEvent): void {
    if (!this.allowDnD) {
      return;
    }
    e.preventDefault();
    this.isDragover = true;
  }

  deactivate(e: DragEvent): void {
    if (!this.allowDnD) {
      return;
    }
    e.preventDefault();
    this.isDragover = false;
  }

  onDrop(e: DragEvent): void {
    if (!this.allowDnD) {
      return;
    }
    e.preventDefault();
    this.isDragover = false;
    this.dropped.emit(e.dataTransfer?.files);
  }

  remove(selectedOptions: SelectionModel<MatListOption>): void {
    this.error = undefined;
    const items: unknown[] = this.selectedOptions(selectedOptions.selected);
    this.removed.emit(items);
    selectedOptions.clear();
    this.cdr.detectChanges();
  }

  selectedOptions(selected: MatListOption[]): unknown[] {
    return selected.map((o) => o.value);
  }
}
