import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { REQUIREMENTS } from '@core/constants/job/requirements';
import { MapperUtil } from '@shared/util/mapper.util';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Requirement } from '@data/job/job.model';

@Component({
  selector: 'recrewt-requirement-item',
  templateUrl: './requirement-item.component.html',
  styleUrls: ['./requirement-item.component.scss'],
})
export class RequirementItemComponent implements OnInit {
  REQUIREMENTS = REQUIREMENTS;

  form!: UntypedFormGroup;

  @Input() req!: Requirement;

  @Output() cancelled = new EventEmitter<boolean>();

  @Output() updated = new EventEmitter<Requirement>();

  @Output() deleteClicked = new EventEmitter();

  @Output() addClicked = new EventEmitter<Requirement>();

  requirementMapping = (req: number) => MapperUtil.decToStr(req, 'requirements');

  ngOnInit(): void {
    if (!this.req) {
      throw Error('No requirements crud object passed');
    }
    this.form = this.fg(this.req);
  }

  fg(req?: Requirement): UntypedFormGroup {
    const category = new UntypedFormControl(req?.category);
    const desc = new UntypedFormControl(req?.desc);
    return new UntypedFormGroup({
      category,
      desc,
    });
  }
}
