<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<form (submit)="submit()" [formGroup]="form" fxLayout="column" fxLayoutAlign="start center" mat-dialog-content>
  <div>
    <p>{{ data.message | translate }}</p>
  </div>
  <mat-form-field appearance="outline">
    <input [matDatepicker]="picker" formControlName="date" matInput required />
    <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
    <mat-datepicker #picker startView="multi-year"></mat-datepicker>
    <mat-label>{{ data.label | translate }}</mat-label>
    <mat-error>{{ data.error | translate }}</mat-error>
  </mat-form-field>
</form>
<div class="p-b-2" fxLayoutAlign="end start" mat-dialog-actions>
  <button (click)="onNoClick()" mat-button>{{ 'FOLDER_DIALOG.cancel' | translate }}</button>
  <button (click)="submit()" color="accent" mat-flat-button type="submit">
    {{ 'FOLDER_DIALOG.confirm' | translate }}
  </button>
</div>
