import { Injectable } from '@angular/core';
import { EntityUIQuery, QueryEntity, SelectAllOptionsB } from '@datorama/akita';
import { JobState, JobStore, JobUIState } from './job.store';
import { CommitState, Job, JobByYearMap, JobUI, MAIN_JOB_STATUS } from '@data/job/job.model';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ContextMode } from '@core/enums/app/context-mode';

@Injectable({ providedIn: 'root' })
export class JobQuery extends QueryEntity<JobState, Job> {
  ui!: EntityUIQuery<JobUIState, JobUI>;

  constructor(protected store: JobStore) {
    super(store);
    this.createUIQuery();
  }

  static hasCommitFailed(status: CommitState | undefined) {
    return status === 'failed';
  }

  static isCommitInProgress(status: CommitState | undefined) {
    return status === 'in_progress';
  }

  static isCommitted(status: CommitState | undefined) {
    return !status || status === 'OK';
  }

  selectFromOtherBranches(currentBranchId: string): Observable<Job[]> {
    return this.selectAll({ filterBy: (it) => it.company !== currentBranchId && it.mode === ContextMode.DEFAULT });
  }

  selectNotArchivedOrTemplate(mode$: Observable<ContextMode>) {
    return this.selectAllForMode(mode$, { filterBy: (job: Job) => MAIN_JOB_STATUS.includes(job.status) });
  }

  selectAllForMode(mode$: Observable<ContextMode>, options?: Partial<SelectAllOptionsB<Job>>) {
    return mode$.pipe(
      switchMap((mode) =>
        this.selectAll({
          ...options,
          filterBy: (s) => s.mode === mode && (!options?.filterBy || (options.filterBy as any)(s)),
        }),
      ),
    );
  }

  hasTemplate(jobId: string): boolean {
    return this.hasEntity((entity) => entity.templateId === jobId);
  }

  selectHasTemplate(jobId: string | null): Observable<boolean> {
    if (!jobId) {
      return of(false);
    }
    return this.selectCount((entity) => entity.templateId === jobId).pipe(map((it) => it > 0));
  }

  getCommitStatus(jobId: string) {
    return this.ui.getEntity(jobId)?.commitState;
  }

  isCommitFailed(id: string): boolean {
    const commitState = this.ui.getEntity(id)?.commitState;
    return JobQuery.hasCommitFailed(commitState);
  }

  selectJobsPerYear(mode$: Observable<ContextMode>, typeId: string, siteId: string): Observable<JobByYearMap> {
    const typeid = +typeId.split('_')[0];
    const specid = typeId.includes('_') ? typeId : null;
    return this.selectAllForMode(mode$, {
      filterBy: (j) =>
        MAIN_JOB_STATUS.includes(j.status) &&
        j.siteId === siteId &&
        j.typeid === typeid &&
        (!specid || j.specialization === specid),
    }).pipe(map((jobs) => this.createJobByYearMap(jobs)));
  }

  createJobByTypeAndSiteMap(jobs: Job[]) {
    return jobs
      .filter((j) => MAIN_JOB_STATUS.includes(j.status) && j.startDate && j.siteId && j.typeid)
      .reduce((dict, job) => {
        const typeId = job.specialization ?? job.typeid?.toString();
        const siteId = job.siteId;
        const key = `${typeId}_${siteId}`;
        const list = dict.get(key) ?? [];
        list.push(job);
        dict.set(key, list);
        return dict;
      }, new Map<string, Job[]>());
  }

  private createJobByYearMap(jobs: Job[]) {
    return jobs.reduce((dict, job) => {
      const key = job.startDate?.getFullYear() ?? null;
      const list = dict.get(key) ?? [];
      list.push(job);
      dict.set(key, list);
      return dict;
    }, new Map<number | null, Job[]>());
  }
}
