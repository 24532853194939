<recrewt-click-editable (cancel)="onCancel()" (update)="onUpdate()" *ngIf="!disabled">
  <ng-template viewMode>
    <p *ngIf="value" class="no-pad p-1">{{ value | date : 'dd.MM.yyyy' }}</p>
    <p *ngIf="!value" class="on-surface-medium no-pad p-1">{{ placeholder ? (placeholder | translate) : '-' }}</p>
  </ng-template>
  <ng-template editMode>
    <input
      (input)="onInputChanged()"
      [formControl]="editControl"
      [matDatepicker]="picker"
      class="no-pad p-1 mat-styled-input mat-body-1"
      editableKeyListener
      fxFlex="grow"
      takeFocus
    />
    <button (click)="picker.open()" mat-icon-button matSuffix><mat-icon>today</mat-icon></button>
    <mat-datepicker #picker (closed)="onInputChanged()" startView="multi-year"></mat-datepicker>
  </ng-template>
</recrewt-click-editable>

<div *ngIf="disabled">
  <div *ngIf="value" [matTooltip]="disabledMessage | translate" class="no-pad p-1 on-surface-medium inline">
    {{ value | date : 'dd.MM.yyyy' }}
  </div>
  <p *ngIf="!value" class="on-surface-medium no-pad p-1">{{ placeholder ? (placeholder | translate) : '-' }}</p>
</div>
