import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CompanyStore } from '@data/company/company.store';
import { Company } from '@data/company/company.model';
import * as _ from 'lodash';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GA_EVENT_ID, GoogleAnalyticsService } from '@core/services/ga/google-analytics.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private url = `${environment.apiUrl}/company`;

  constructor(private http: HttpClient, private companyStore: CompanyStore, private ga: GoogleAnalyticsService) {}

  get(companyId: string): void {
    this.http.get<Company>(`${this.url}/${companyId}`).subscribe((company) => {
      this.companyStore.update(company);
      this.companyStore.setHasCache(true);
    });
  }

  getOnce(companyId: string) {
    return this.http.get<Company>(`${this.url}/${companyId}`);
  }

  update(companyId: string, changes: Partial<Company>, copyToBranches: boolean): Observable<any> | null {
    if (_.isEmpty(changes)) {
      return null;
    }
    return this.http.put(`${this.url}/${companyId}`, { company: changes, copyToBranches }).pipe(
      tap(() => {
        this.ga.eventEmitter(GA_EVENT_ID.company_profile_updated, 'company', GA_EVENT_ID.company_profile_updated);
        this.companyStore.update(changes);
      }),
    );
  }
}
