<div
  [matMenuTriggerFor]="contextMenu"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  style="visibility: hidden; position: fixed"
></div>
<mat-menu #contextMenu="matMenu">
  <ng-template let-item="target" matMenuContent>
    <ng-container>
      <button (click)="retry(item)" *ngIf="strategy.showRetry(item)" mat-menu-item>
        <mat-icon>sync</mat-icon>
        <span>{{ 'MENUS.RESOURCE_CONTEXT.retry' | translate }}</span>
      </button>
      <button (click)="delete(item)" *ngIf="strategy.showDelete(item)" mat-menu-item>
        <mat-icon>delete</mat-icon>
        <span>{{ 'MENUS.RESOURCE_CONTEXT.delete' | translate }}</span>
      </button>
    </ng-container>
  </ng-template>
</mat-menu>
